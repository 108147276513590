<template>
  <div class="page">
    <div class="top_box">
      <img :src="detail.activityPicture" alt="" />
    </div>
    <div class="center_box">{{ detail.activityName }}</div>
    <div class="bottom_box">
      <div class="in_box">
        <div class="title">
          <div class="text">活动规则</div>
          <div class="color_block"></div>
        </div>
        <div class="detail">
          <div class="item">
            <div class="left">报名开始时间：</div>
            <div class="right">{{ detail.applyStartDate }}</div>
          </div>
          <div class="item">
            <div class="left">活动开始时间：</div>
            <div class="right">{{ detail.startDate }}</div>
          </div>
          <div class="item">
            <div class="left">活动人数：</div>
            <div class="right">
              <span>{{ detail.applyUserCount }}</span
              >/{{ detail.limitCount }}
            </div>
          </div>
          <div class="item">
            <div class="left">活动地点：</div>
            <div class="right">{{ detail.activityAddress }}</div>
          </div>
        </div>
        <div class="title">
          <div class="text">活动内容</div>
          <div class="color_block"></div>
        </div>
        <div class="content">
          {{ detail.content }}
        </div>
      </div>
    </div>
    <div
      class="btn"
      v-if="detail.isJoin == '2'"
      @click.stop="joinOrNot('确定参与', 1)"
    >
      参与
    </div>
    <div
      class="btn_no"
      v-else-if="detail.isJoin == '1'"
      @click.stop="joinOrNot('取消参与', 2)"
    >
      取消参与
    </div>
    <div class="btn_empty" v-else-if="detail.isJoin == '3'">已结束</div>
    <div class="btn_empty" v-else-if="detail.isJoin == '4'">已满员</div>
    <div class="btn_empty" v-else-if="detail.isJoin == '5'">进行中</div>
    <v-dialog
      confirmButtonColor="#FE9615"
      v-model="isDialog"
      @confirm="confirm"
    >
      <p class="join_message">是否{{ joinStr }}该活动？</p>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getDetailsUrl, joinOrQuitUrl } from "./api.js";
export default {
  name: "communityActivitiesDetail",
  data() {
    return {
      isDialog: false,
      joinStr: "",
      id: null,
      detail: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getDetailData();
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    getDetailData() {
      let params = {
        id: this.id,
        userId: this.userId,
      };
      this.$axios
        .get(`${getDetailsUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.detail = res.data;
          }
        });
    },
    joinOrNot(str, flag) {
      this.joinFlag = flag;
      this.joinStr = str;
      this.isDialog = true;
    },
    confirm() {
      let params = {
        userId: this.userId,
        id: this.id,
        joinFlag: this.joinFlag,
      };
      this.$axios
        .post(`${joinOrQuitUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$toast(res.msg);
            this.getDetailData();
          }
        });
      this.isDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;

  .join_message {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 86px 124px 58px;
    white-space: nowrap;
  }

  .btn_empty {
    width: 550px;
    height: 66px;
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-weight: 600;
    background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
    color: #a8a8a8;
    position: fixed;
    bottom: 114px;
    left: 50%;
    transform: translateX(-50%);
  }

  .btn_no {
    width: 550px;
    height: 66px;
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-weight: 600;
    color: #fe9616;
    border: 2px solid;
    position: fixed;
    bottom: 114px;
    left: 50%;
    transform: translateX(-50%);
  }

  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 114px;
    left: 50%;
    transform: translateX(-50%);
  }

  .bottom_box {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;

    .in_box {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      padding-top: 38px;
      padding-bottom: 66px;
      box-sizing: border-box;

      .content {
        line-height: 36px;
        padding: 0 36px;
        font-size: 26px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.85);
        word-break: break-all;
      }

      .detail {
        width: 100%;
        padding: 0 36px;
        box-sizing: border-box;
        margin-bottom: 64px;

        .item {
          display: flex;
          font-size: 26px;
          align-items: center;
          margin-bottom: 12px;

          .left {
            width: 200px;
            color: rgba(0, 0, 0, 0.85);
          }

          .right {
            flex: 1;
            color: rgba(0, 0, 0, 0.5);

            span {
              color: #fe9717;
            }
          }
        }
      }

      .title {
        width: 100%;
        height: 50px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        font-size: 32px;
        position: relative;
        margin-bottom: 62px;

        .color_block {
          width: 174px;
          height: 16px;
          background: #e2e2e2;
          border-radius: 7px;
          position: absolute;
          bottom: 0;
          left: 36px;
        }

        .text {
          position: absolute;
          left: 60px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;
        }
      }
    }
  }

  .center_box {
    padding: 0 32px 50px 32px;
    font-size: 34px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }

  .top_box {
    padding: 0 34px;
    width: 100%;
    height: 318px;
    box-sizing: border-box;
    margin: 30px 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
